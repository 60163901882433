import styled from 'styled-components'

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: #3044a2;
  }
`
export const CardIcon = styled.div`
  margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CardHeaderTitle = styled.span`
  line-height: 1.5rem;
  font-size: 1.25rem;
`
