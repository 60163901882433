import { PlayersInfo } from '@components/Accounts/PlayersInfo/PlayersInfo'

type Route = {
  component: JSX.Element
  exact: boolean
  path: string
}

const routes = (): Route[] => [
  {
    component: <PlayersInfo />,
    exact: true,
    path: '/apps/accounts/playersinfo',
  },
]

export default routes
