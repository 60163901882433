// API
import { accountsApi } from '@shared/api/Accounts/AccountsApi'

// TYPES
import {
  GetPlayerInfoField,
  GetPlayersInfoResponse,
} from '@shared/types/AccountsTypes'

const getPlayersInfo = (field: GetPlayerInfoField, values: string[]): Promise<GetPlayersInfoResponse> => {
  const queryString = `field=${field}&${values.map(v => `values[]=${v}`).join('&')}`
  return accountsApi.get(`/backoffice/accounts/playersinfo?${queryString}`)
}

export {
  getPlayersInfo
}
