import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import { getPlayersInfo } from '@services/Accounts/AccountsService'

// TYPES
import {
  GetPlayerInfoField,
  GetPlayersInfoResponse,
  PlayerInfo,
} from '@shared/types/AccountsTypes'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'

import { Search as SearchIcon } from '@mui/icons-material'

import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

const MAX_VALUES_COUNT = 100

const PlayersInfo = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  
  const [field, setField] = useState<GetPlayerInfoField>('playerIds')
  
  const [includePlayerId, setIncludePlayerId] = useState(true)
  const [includeAccountId, setIncludeAccountId] = useState(true)
  const [includeCredential, setIncludeCredential] = useState(true)
  const [includeNick, setIncludeNick] = useState(false)

  const [csvContent, setCsvContent] = useState('')
  
  
  const [searchErrors, setSearchErrors] = useState<string[]>([])
  const [playersInfo, setPlayersInfo] = useState<PlayerInfo[]>([])

  const _getPlayersInfo = async (
    field: GetPlayerInfoField,
    values: string[]
  ): Promise<void> => {
    try {
      setIsLoading(true)

      const queryValues = values.slice(0, MAX_VALUES_COUNT)
      const response: GetPlayersInfoResponse = await getPlayersInfo(field, queryValues)
      setPlayersInfo(response.players)

      const errors = []
      if(values.length > MAX_VALUES_COUNT) {
        errors.push(`Número máximo de valores para a busca é ${MAX_VALUES_COUNT}, os valores excedentes foram descartados.`)
      }

      if(values.length !== new Set(values).size) {
        errors.push('Existem valores duplicados.')
      }

      if(queryValues.length !== response.players.length) {
        errors.push('Um ou mais players não foram encontrados')
      }

      const someFieldIsNotPresent = response.players.some(p => 
        (includePlayerId && (!p.id || p.id === 'null' || p.id === ''))
        || (includeAccountId && (!p.accountId || p.accountId === 'null' || p.accountId === ''))
        || (includeCredential && (!p.credential || p.credential === 'null' || p.credential === ''))
        || (includeNick && (!p.nick || p.nick === 'null' || p.nick === ''))
      )
      if(someFieldIsNotPresent) {
        errors.push('Alguns campos solicitados não estão com valor informado')
      }

      const nickWithComma = includeNick && response.players.some(p => (p.nick && p.nick.includes(',')))
      if(nickWithComma) {
        errors.push('Um ou mais players possui nick com vírgula')
      }

      setSearchErrors(errors)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const values = csvContent
      .replace(/[\r'" ]/g, '')
      .replace(/(,+|;+|\s+)/g, '\n')
      .replace(/(\n+)/g, '\n')
      .replace(/^\n|\n$/g, '')
      .split(/[\n]/g)

    _getPlayersInfo(field, values)
  }

  const getResult = (): string => {
    const headers = []
    const fields = []

    if(includePlayerId) {
      headers.push('gcId')
      fields.push('id')
    }
    if(includeAccountId) {
      headers.push('accountId')
      fields.push('accountId')
    }
    if(includeCredential) {
      headers.push('steamId64')
      fields.push('credential')
    }
    if(includeNick) {
      headers.push('nick')
      fields.push('nick')
    }

    const players = playersInfo
      .map((player) => fields.map((field) => (player[field] || '')).join(', '))
      .join('\n')
    
    return `${headers.join(', ')}\n${players}`
  }

  const _copyResult = (): void => {
    navigator.clipboard.writeText(getResult())
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Busca em Massa">
        <SearchIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const renderLoading = (): JSX.Element => {
    return isLoading 
      ? <Loader type="overlay" />
      : <></>
  }

  const renderErrors = (): JSX.Element => {
    if(!searchErrors.length) {
      return <></>
    }

    return (
      <div>
        { searchErrors.map((error, index) => (<Styled.Error key={`error_${index}`}>- {error}</Styled.Error>)) }
      </div>
    )
  }

  const renderResult = (): JSX.Element => {
    if(!playersInfo.length) {
      return <></>
    }

    return (
      <Grid container spacing={2} sx={{ mt: 0.2 }}>
        <Grid item xs={12}>
          <TextField
            key="playersInfo"
            label="Resultado"
            placeholder="Players"
            multiline={true}
            maxRows={10}
            style={{ width: '100%' }}
            value={getResult()}
          />
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-start">
            <Button variant="contained" onClick={() => _copyResult()}>
              Copiar
            </Button>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Card>
      <CardHeader title={renderCardHeader()} />
      <CardContent>
        <form onSubmit={handleSearchSubmit}>
          <Grid container spacing={2} sx={{ mt: 0.2, mb: 3 }}>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel>Campo a ser buscado</FormLabel>
                <RadioGroup
                  row
                  defaultValue="playerIds"
                  key="radio-buttons-group"
                  value={field}
                  onChange={(e) =>
                    setField(e.target.value as GetPlayerInfoField)
                  }
                >
                  <FormControlLabel
                    value="playerIds"
                    control={<Radio />}
                    label="GC ID"
                  />
                  <FormControlLabel
                    value="accountIds"
                    control={<Radio />}
                    label="Account ID"
                  />
                  <FormControlLabel
                    value="credentials"
                    control={<Radio />}
                    label="SteamID64"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl>
                <FormLabel>Campos a serem incluídos</FormLabel>
                <div>
                  <FormControlLabel
                    key="includePlayerId"
                    control={<Checkbox />}
                    label="GC ID"
                    checked={includePlayerId}
                    onChange={(e: any) => setIncludePlayerId(e.target.checked)}
                  />
                  <FormControlLabel
                    key="includeAccountId"
                    control={<Checkbox />}
                    label="Account ID"
                    checked={includeAccountId}
                    onChange={() => setIncludeAccountId(previous => !previous)}
                  />
                  <FormControlLabel
                    key="includeCredential"
                    control={<Checkbox />}
                    label="SteamID64"
                    checked={includeCredential}
                    onChange={() => setIncludeCredential(previous => !previous)}
                  />
                  <FormControlLabel
                    key="includeNick"
                    control={<Checkbox />}
                    label="Nick"
                    checked={includeNick}
                    onChange={() => setIncludeNick(previous => !previous)}
                  />
                </div>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                key="csvContent"
                label="Valores para busca"
                placeholder={'valor 1\nvalor 2\nvalor 3\n...'}
                multiline={true}
                minRows={5}
                maxRows={10}
                style={{ width: 350 }}
                onChange={(e) => setCsvContent(e.target.value)}
                value={csvContent}
              />
            </Grid>

            <Grid item>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  Buscar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>

        { renderLoading() }
        { renderErrors() }
        { renderResult() }
      </CardContent>
    </Card>
  )
}

export { PlayersInfo }
