import axios from 'axios'
import { ACCOUNT_API_URL } from '@config/environment'

// SHARED INTERCEPTORS
import { _shared } from '../../../../../root/src/app/shared/shared'
const shared = _shared

const accountsApi = axios.create({
  baseURL: ACCOUNT_API_URL,
})

accountsApi.interceptors.request.use(shared.authInterceptor)
accountsApi.interceptors.response.use(
  shared.responseInterceptor,
  shared.checkTokenValidityInterceptor
)

export { accountsApi }
